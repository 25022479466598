import { Alert, Button, Input, Label } from '@windmill/react-ui'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { default as ImageDark, default as ImageLight } from '../assets/images/Backgrounds-1.jpg'
import { useAuth } from "../context/AuthContext"
import { GoogleIcon } from '../assets/icons'


function Login(props) {
  const { login } = useAuth();
  const history = useHistory()
  const [error, setError] = useState(props.message);
  

  
  async function handleSubmit(e) {
    e.preventDefault();

    try {
      onSetError("")
      await login(e.target.email.value, e.target.password.value);
      history.push("/app")
    } catch (e) {
      onSetError("Ungültiger Benutzer oder Passwort.")
    }

  }

  /* async function handleSubmitGoogle(e) {
    e.preventDefault();

    try {
      onSetError("")
      await loginWithGoogle();
      history.push("/app")
    } catch (e) {
      onSetError("Ungültiger Benutzer oder Passwort.")
    }
  } */

  const onSetError = (message) => setError(message)


  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 bg-authBackground dark:bg-gray-900">
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-3xl shadow-xl shadow-auth dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src={ImageLight}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src={ImageDark}
              alt="Office"
            />
          </div>
          <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2 ">
            <div className="w-full">
              {error && (
                <Alert className="mb-6" type="danger">
                  {error}
                </Alert>
              )}
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">Login</h1>
              <form onSubmit={(e) => handleSubmit(e)}>
                <Label>
                  <span>E-Mail <span className="font-bold text-red-500">*</span></span>
                  <Input name="email" className="mt-1" type="email" placeholder="musterfrau@ecg-whv.de" />
                </Label>

                <Label className="mt-4">
                  <span>Passwort <span className="font-bold text-red-500">*</span></span>
                  <Input name="password" className="mt-1" type="password" placeholder="***************" />
                </Label>

                <Button type="submit" className="mt-4 bg-primary-500" block>
                  Einloggen
                </Button>
              </form> 
              <p className="mt-4">
                <Link
                  className="text-sm font-medium text-primary-600 dark:text-primary-400 hover:underline"
                  to="/signup"
                >
                  Hast du noch keinen Account? Signup
                </Link>
              </p>

              {/* <hr className="my-8" />

              <Button block layout="outline" onClick={(e) => handleSubmitGoogle(e)}>
                <GoogleIcon className="w-4 h-4 mr-2" aria-hidden="true" />
                Google
              </Button> */}

            </div>
          </main>
        </div>
      </div>
    </div>
  )
}

Login.defaultProps = {
  message: ""
}

export default Login;