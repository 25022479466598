import React from 'react'
import BeatLoader from "react-spinners/BeatLoader";


function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 dark:text-gray-400 dark:bg-gray-900">
      <BeatLoader color='#3BAFDA' size={15} />
    </div>
  )
}

export default ThemedSuspense
