import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import Login from "../pages/Login";

function PrivateRoute({ component: Component, ...rest  }) {
  const { currentUser } = useAuth();

  return (

    <Route
      {...rest}
      render={props => {
        if(!currentUser){
          return <><Login message=""/> <Redirect to="/login"/></>
        }else {
          if(currentUser.isAllowed){
            return <Component {...props} />
          }else {
            return <><Login message="Du wurdest noch nicht freigeschaltet. Warte oder kontaktiere einen Admin."/> <Redirect to="/login"/></>
          }
        }        
         
      }}
    ></Route>
  );
}

export default PrivateRoute;