import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  QueryClient,
  QueryClientProvider
} from 'react-query';
import ThemedSuspense from "./components/ThemedSuspense";
import { SidebarProvider } from './context/SidebarContext';
import { Windmill } from '@windmill/react-ui';
import theme from "./utils/theme";


const queryClient = new QueryClient();

ReactDOM.render(
    <SidebarProvider>
      <Suspense fallback={<ThemedSuspense />}>
        <QueryClientProvider client={queryClient}>
          <Windmill usePreferences theme={theme}>
            <App />
          </Windmill>
        </QueryClientProvider>
      </Suspense>
    </SidebarProvider>,
  document.getElementById('root')
);
