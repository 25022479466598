import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'



const app = firebase.initializeApp({
    apiKey: "AIzaSyD33KUwU5ZWsxdxc--flsSu3Dm0H1lFSA4",
    authDomain: process.env.REACT_APP_MESSAGING_SENDER_ID,
    databaseURL: process.env.REACT_APP_STORAGE_BUCKET,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DATABASE_URL,
    messagingSenderId: process.env.REACT_APP_AUTH_DOMAIN,
    appId: process.env.REACT_APP_API_KEY
});


app.firestore().settings({
    experimentalForceLongPolling: true, // this line
    useFetchStreams: false, // and this line
})

const auth = app.auth()
const googleAuth = new firebase.auth.GoogleAuthProvider();
const db = app.firestore()
const storage = app.storage();



export {
    app as default, 
    auth,
    db,
    firebase,
    googleAuth,
    storage
}


