import React, { lazy } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./routes/privateRoutes";


const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'))
const SignUp = lazy(() => import("./pages/Signup"));

function App() {

  return (
    <>
      <AuthProvider>
        <Router>
          <AccessibleNavigationAnnouncer />
          <Switch>
            <PrivateRoute path="/app" component={Layout}/>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Redirect exact from="/" to="/app" />
          </Switch>
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
