import React, { useContext, useEffect, useState } from "react"
import { auth, db, googleAuth, firebase } from "../utils/api/firebase"


const AuthContext = React.createContext()


export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  /* async function loginWithGoogle() {
    const cred = await auth.signInWithPopup(googleAuth);

    if((await db.collection("User").doc(cred.user.uid).get()).exists){
      await db.collection("User").doc(cred.user.uid).get().then(doc => {
        return Object.assign(cred.user, doc.data())
      });
    }else {
      return db.collection("User").doc(cred.user.uid).set({
        isOnline: false,
        isAllowed: false,
        prename: cred.user.displayName.split(" ")[0], 
        surname: cred.user.displayName.split(" ")[1],
        role: "User"
      })
    }
    
  } */

 

  const signup = async (email, password, prename, surname) => {
    const cred = await auth.createUserWithEmailAndPassword(email, password);

    return db.collection("User").doc(cred.user.uid).set({
      emailFromUser: email,
      isOnline: false,
      isAllowed: false,
      prename: prename, 
      surname: surname,
      role: "User"
    })
  }

  async function login (email, password) {

    await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const res = await auth.signInWithEmailAndPassword(email, password);
    await db.collection("User").doc(res.user.uid).update({isOnline: true});
    
    await db.collection("User").doc(res.user.uid).get().then(doc => {
      return Object.assign(res.user, doc.data())
    });
    
  }

  

  async function logout() {
    await db.collection("User").doc(currentUser.uid).update({isOnline: false});
    return auth.signOut()
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email)
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password)
  }



  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async user => {
      if (user) {
        db.collection("User").doc(user.uid).get().then(async doc => {
          Object.assign(user, doc.data())
          setCurrentUser(user)
          setLoading(false)
        });
      } else {
        setCurrentUser(user)
        setLoading(false)
      }
    })

    
    return unsub;
  }, [])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    /* loginWithGoogle */
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}